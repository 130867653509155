import type { FC } from 'react';
import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl-next';
import { styled } from '@compiled/react';

import { ButtonItem } from '@atlaskit/menu';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import { LegacyContentIcon } from '@confluence/icons/entry-points/LegacyContentIcon';

import type { CreateSpaceDisplayItemProps } from './CreateSpaceButton';

const i18n = defineMessages({
	createASpace: {
		id: 'create-space.menu-item-with-icon',
		defaultMessage: 'Space',
		description:
			'Text for button in the global Create dropdown that opens the create space dialog where users can create a new space.',
	},
});

const nonStandardIconSize = '18px !important';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentIconWrapper = styled.div({
	display: 'flex',
	flexShrink: '0',
	justifyContent: 'center',
	width: token('space.300', '24px'),

	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'svg, span': {
		height: nonStandardIconSize,
		width: nonStandardIconSize,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ContentTitle = styled.div({
	fontWeight: 500,
	color: token('color.text', N800),
});

const Title = (
	<ContentTitle>
		<FormattedMessage {...i18n.createASpace} />
	</ContentTitle>
);

const Avatar = (
	<ContentIconWrapper>
		<LegacyContentIcon size="small" type="space" label="" />
	</ContentIconWrapper>
);

export const CreateSpaceMenuItemNav3WithIcon: FC<CreateSpaceDisplayItemProps> = ({
	onClick,
	onMouseDown,
}) => (
	<ButtonItem
		iconBefore={Avatar}
		onClick={onClick}
		onMouseDown={onMouseDown}
		aria-haspopup="dialog"
		testId="create-menu-link-item"
	>
		{Title}
	</ButtonItem>
);

import type { FC, MutableRefObject } from 'react';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';

import Button from '@atlaskit/button/standard-button';
import type { Appearance, Spacing } from '@atlaskit/button/types';

import type { CreateSpaceDisplayItemProps } from './CreateSpaceButton';
import { i18n } from './createSpaceButtonI18n';

type CreateSpaceStandardButtonProps = CreateSpaceDisplayItemProps & {
	appearance: Appearance;
	spacing: Spacing;
	createSpaceButtonRef?: MutableRefObject<HTMLElement | null>;
};

export const CreateSpaceStandardButton: FC<CreateSpaceStandardButtonProps> = ({
	onClick,
	onMouseDown,
	appearance,
	spacing,
	createSpaceButtonRef,
}) => (
	<Button
		id="create-space-button-next"
		onClick={onClick}
		onMouseDown={onMouseDown}
		appearance={appearance}
		spacing={spacing}
		aria-haspopup="dialog"
		ref={createSpaceButtonRef}
	>
		<FormattedMessage {...i18n.createSpace} />
	</Button>
);

export const CreateSpaceStandardButtonLoading = () => (
	<CreateSpaceStandardButton
		onClick={() => {}}
		onMouseDown={() => {}}
		appearance="default"
		spacing="compact"
	/>
);

import type { FC, MouseEvent, KeyboardEvent, MutableRefObject } from 'react';
import React, { useCallback, useContext, useRef } from 'react';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { AppNavigationContext } from '@confluence/app-navigation-context';
import { LoadableAfterPaint } from '@confluence/loadable';
import { useHardStorageEnforcement } from '@confluence/storage-enforcement/entry-points/HardEnforcement/useHardStorageEnforcement';
import { preloadWebItemLocation, CREATE_SPACE_DIALOG } from '@confluence/web-item-location';

import { useCreateSpaceStore } from '../CreateSpaceDrawer/CreateSpaceStore';
import { useCanCreateSpace } from '../useCanCreateSpace';

import { CreateSpaceStandardButtonLoading } from './CreateSpaceStandardButton';
import { CreateSpaceMenuItemNav3WithIcon } from './CreateSpaceMenuItemNav3WithIcon';

const CreateSpaceMenuItem = LoadableAfterPaint({
	loader: async () =>
		(await import(/* webpackChunkName: "loadable-CreateSpaceMenuItem" */ './CreateSpaceMenuItem'))
			.CreateSpaceMenuItem,
});

const CreateSpaceMenuItemNav3 = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateSpaceMenuItemNav3" */ './CreateSpaceMenuItemNav3'
			)
		).CreateSpaceMenuItemNav3,
});

const CreateSpaceStandardButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateSpaceStandardButton" */ './CreateSpaceStandardButton'
			)
		).CreateSpaceStandardButton,
	loading: () => <CreateSpaceStandardButtonLoading />,
});

const CreateSpacePlusButton = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-CreateSpacePlusButton" */ './CreateSpacePlusButton'
			)
		).CreateSpacePlusButton,
});

type CreateSpaceAppearance =
	| 'default'
	| 'link'
	| 'plus'
	| 'menu-item'
	| 'menu-item-nav3'
	| 'menu-item-nav3-with-icon'
	| 'compact';

export type CreateSpaceDisplayItemProps = {
	onClick: (e: MouseEvent | KeyboardEvent) => void;
	onMouseDown: () => void;
};

export type CreateSpaceButtonProps = {
	appearance: CreateSpaceAppearance;
	source: string;
	isTop?: boolean;
	onClick?: () => void;
	customFocusRef?: MutableRefObject<HTMLElement | null>;
};

export const CreateSpaceButton: FC<CreateSpaceButtonProps> = ({
	appearance,
	source,
	isTop,
	onClick,
	customFocusRef,
}) => {
	const { canCreateSpace } = useCanCreateSpace();
	const [, { openDrawer }] = useCreateSpaceStore();
	const { enforceStorageLimit } = useHardStorageEnforcement({
		source: 'create-space-button',
	});
	const { resetStickySearchRef } = useContext(AppNavigationContext);
	const createSpaceButtonRef = useRef<HTMLElement | null>(null);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const preloadCreateSpace = () => {
		void preloadWebItemLocation({
			location: CREATE_SPACE_DIALOG,
		});
	};

	const handleCreate = useCallback(
		(e: MouseEvent | KeyboardEvent) => {
			resetStickySearchRef?.current?.();
			openDrawer(customFocusRef || createSpaceButtonRef);
			createAnalyticsEvent({
				type: 'sendUIEvent',
				data: {
					action: 'clicked',
					actionSubject: 'button',
					actionSubjectId: 'createSpaceButton',
					source,
					attributes: {
						isTop: Boolean(isTop),
					},
				},
			}).fire();
			e.stopPropagation();
			onClick?.();
		},
		[
			resetStickySearchRef,
			openDrawer,
			customFocusRef,
			createAnalyticsEvent,
			source,
			isTop,
			onClick,
		],
	);

	const handleClick = useCallback(
		(e: MouseEvent | KeyboardEvent) => enforceStorageLimit(handleCreate)(e),
		[enforceStorageLimit, handleCreate],
	);

	if (!canCreateSpace) {
		return null;
	}

	if (appearance === 'menu-item') {
		return <CreateSpaceMenuItem onClick={handleClick} onMouseDown={preloadCreateSpace} />;
	} else if (appearance === 'menu-item-nav3') {
		return <CreateSpaceMenuItemNav3 onClick={handleClick} onMouseDown={preloadCreateSpace} />;
	} else if (appearance === 'menu-item-nav3-with-icon') {
		return (
			<CreateSpaceMenuItemNav3WithIcon onClick={handleClick} onMouseDown={preloadCreateSpace} />
		);
	} else if (appearance === 'compact') {
		return (
			<CreateSpaceStandardButton
				onClick={handleClick}
				onMouseDown={preloadCreateSpace}
				appearance="default"
				spacing="compact"
				createSpaceButtonRef={createSpaceButtonRef}
			/>
		);
	} else if (appearance === 'plus') {
		return (
			<CreateSpacePlusButton
				onClick={handleClick}
				onMouseDown={preloadCreateSpace}
				createSpaceButtonRef={createSpaceButtonRef}
			/>
		);
	}

	return (
		<CreateSpaceStandardButton
			onClick={handleClick}
			onMouseDown={preloadCreateSpace}
			appearance={appearance}
			spacing="default"
			createSpaceButtonRef={createSpaceButtonRef}
		/>
	);
};

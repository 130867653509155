import type { FC } from 'react';
import React, { memo, useState, useCallback } from 'react';
// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line no-restricted-imports, @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from '@emotion/styled';

import { token } from '@atlaskit/tokens';
import { N20A } from '@atlaskit/theme/colors';
import WorldIcon from '@atlaskit/icon/glyph/world';
import UserAvatarCircleIcon from '@atlaskit/icon/glyph/user-avatar-circle';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/design-system/no-styled-tagged-template-expression -- Ignored via go/DSP-18766
const SpaceImg = styled.img<{ size: Size; loaded: boolean }>`
	background-color: ${(props) => (!props.loaded ? token('color.skeleton', N20A) : 'transparent')};
	flex-shrink: 0;
	border-radius: ${(props) => AvatarStyles[props.size].borderRadius};
	border: 0;
	width: ${(props) => AvatarStyles[props.size].width};
	height: ${(props) => AvatarStyles[props.size].width};
`;

const AvatarStyles = {
	xsmall: { borderRadius: '4px', width: '20px' },
	small: { borderRadius: '2px', width: '24px' },
	medium: { borderRadius: '3px', width: '32px' },
	large: { borderRadius: '3px', width: '40px' },
	xlarge: { borderRadius: '6px', width: '96px' },
};

export const types = {
	global: WorldIcon,
	personal: UserAvatarCircleIcon,
};

type Size = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

export interface SpaceIconProps {
	type?: string;
	label?: string;
	size?: Size;
	src?: string;
	testId?: string;
}

export const SpaceIcon: FC<SpaceIconProps> = memo(
	({ type, label, size = 'medium', src, testId }) => {
		const [imgLoaded, setImgLoaded] = useState(!src);
		const setLoaded = useCallback(() => {
			setImgLoaded(true);
		}, [setImgLoaded]);

		if (src) {
			return (
				<SpaceImg
					size={size!}
					loaded={imgLoaded}
					src={src?.includes('/wiki') ? src : `/wiki${src}`}
					alt={label}
					onLoad={setLoaded}
					onError={setLoaded}
					data-testid={testId}
				/>
			);
		}

		const Icon: React.ElementType = (type && types[type as keyof typeof types]) || WorldIcon;

		return <Icon size={size} label={label} testId={testId} />;
	},
);

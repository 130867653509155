import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	createSpace: {
		id: 'create-space.button',
		defaultMessage: 'Create a space',
		description:
			'Text for button that opens the create space drawer where users can create a new space.',
	},
});

import { defineMessages } from 'react-intl';

export const spaceStarI18n = defineMessages({
	star: {
		id: 'action-buttons.space.star.label',
		defaultMessage: 'Star this space',
		description:
			'Label for unfilled star icon button which indicates that the space is unstarred & stars the space when clicked',
	},
	unstar: {
		id: 'action-buttons.space.unstar.label',
		defaultMessage: 'Unstar this space',
		description:
			'Label for filled star icon button which indicates that the space is starred & unstars the space when clicked',
	},
});
